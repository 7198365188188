import React from "react"
import styles from './index.module.css'
const SmartSence = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>核心优势 助力增长</div>
        <div className={styles.subTitle}>
          以技术赋能，用数据驱动，借生态助力，用我们的能力实现客户的价值
        </div>
        <div className={styles.group}>
          <div className={styles.item}>
            <div className={styles.itemCircle}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/d04ee8d9306a4ec48a9a349f524042c9"
                }
                alt=""
                // style="width:60PX;height:60PX;"
              />
            </div>
            <div className={styles.itemTitle}>系统化解决方案</div>
            <div className={styles.itemText}>
              提供端云一体、软硬配套等系统化方案支持传统社区快速高效地实现全面升级
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemCircle}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/f51b2f403eda492a9975b1e0ac160170"
                }
                alt=""
              />
            </div>
            <div className={styles.itemTitle}>社区数据闭环</div>
            <div className={styles.itemText}>
              打造人-空间-服务数据闭环，实现数字化社区全景运营
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemCircle}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/8bd0488b04f44369a5b7524675111177"
                }
                alt=""
              />
            </div>
            <div className={styles.itemTitle}>实力合作伙伴</div>
            <div className={styles.itemText}>
              与阿里人居平台深度合作，联手业界领先合作伙伴，缔造智能、安全社区
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartSence
