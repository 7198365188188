import React from 'react';
import styles from './index.module.css'

const NavVideo = () => {
    return(
        <div className={styles.video}>
            <video autoPlay loop={true} muted="muted">
                <track default></track>
                <source src="https://static.lianmeihu.com/files/lmh_dev/general/x-uploader/2021/March/30/b0fa5894ada94466ba9c6d52b513b9bc" />
            </video>
        </div>
    )
}
export default NavVideo