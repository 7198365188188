import React, { useEffect } from "react"
// import ReactDOM from "react-dom"
// import ReactSwipe from "react-swipe"
import Swiper from "swiper"
import "swiper/swiper.min.css"
import './index.css'
import styles from './index.module.css'
const SmartSence = () => {
  useEffect(() => {
    new Swiper("#swiper-container", {
      slidesPerView: 'auto',
      // spaceBetween: 20,
      // centeredSlides: ,
    })
  }, []);
  return (
    <div style={{ paddingBottom: 120 }}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>关键场景 解锁智能</div>
          <div className={styles.subTitle}>
            深入了解物业管理服务难题，全方位升级空间智能化服务能力
          </div>
        </div>
        <div className="swiper-container" id="swiper-container">
          <div className="swiper-wrapper">
            <div
              className="swiper-slide"
              style={{ width: "calc(50vw - 600PX)" }}
            ></div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/298f2044b9544d0692f38510163ea98b"
                }
                alt=""
              />
              <p>智慧停车</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/13ef77c984b74980a947a7f55bc4f3f7"
                }
                alt=""
              />
              <p>智慧门禁</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/2b9ad2eb563b424b900f2ead50f9264a"
                }
                alt=""
              />
              <p>报事保修</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/9fc996cb45494560ad28a2aae8634b5b"
                }
                alt=""
              />
              <p>在线缴费</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/f62bc16d8a2c44538e3c4ade0dd14e81"
                }
                alt=""
              />
              <p>服务预约</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/c58bfc6c3cb24cad9b42122b61b49a09"
                }
                alt=""
              />
              <p>政府办事</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/4dd863607f8f4232b86a60b1739e466d"
                }
                alt=""
              />
              <p>社交电商</p>
            </div>
            <div className="swiper-slide">
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/0f5be1a03b7144c18b14be9281af9004"
                }
                alt=""
              />
              <p>邻里社交</p>
            </div>
            <div
              className="swiper-slide"
              style={{ width: "calc(50vw - 600PX)", marginRight: 0 }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartSence
