import React from 'react';
import styles from './index.module.css'

const Banner = ({ bannerUrl,imgLogo,containerStyle={},json }) =>{
  return (
    <div>
    <div style={{display: 'none'}}>
      <image alt="logo" src={"https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/May/6/7eb1cf403cad4f4797466006a18df7c5"}/>
    </div>
    <div className={styles.container} style={containerStyle}>
      <div className={styles.content}>
        <div className={styles.title}>
          {json.title}
          <br/>
          {json.subTitle}
        </div>
        <div className={styles.img1}>

          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/882f25a02a4d4f399c9fdf728c741422"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img2}>
          <img
            src={
              bannerUrl
                ? bannerUrl
                : "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/1e33ed3676454947906f795edb2b566a"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img3}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/12c2b145b17e401b95ec67da4a9db077"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img4}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/bcc3687c28614e189644910e307cb6df"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.imgLogo}>
          <img
            src={
              imgLogo
                ? imgLogo
                : "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/2f395b52e2994ac9b1ff5148d7d05d9d"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
    </div>
  )
}

export default Banner
