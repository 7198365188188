import React from 'react';
import styles from './index.module.css'

const Win = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>聚焦痛点 多方共赢</div>
        <div className={styles.subTitle}>
          依托领先的物联网技术与大数据服务，解决社区运营核心难题，实现企业、商家与政府机构的降本增效，全面提高业主满意度
        </div>
        <div className={styles.group}>
          <div className={styles.item}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/7d48e6e5c943437a80cf5fa4a62e2905"
              }
              alt=""
              style={{ width: 285, height: 300 }}
            />
            <div className={styles.itemTitle}>物业公司</div>
            <div className={styles.itemText}>
              <span className={styles.good}>智能化升级实现社区数字化全景运营，降本增效，提升服务质量与业务满意度，增加业主粘性，提升物业品牌价值</span>
              <span className={styles.bad}>社区运维效率低、成本高，与业主沟通受限导致服务满意度低，物业品牌价值难提升</span>
            </div>
          </div>
          <div className={styles.item}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/0af8a81339b3466fa9b62386a38e4ae5"
              }
              alt=""
              style={{ width: 285, height: 300 }}
            />
            <div className={styles.itemTitle}>企业商户</div>
            <div className={styles.itemText}>
              <span className={styles.good}>优质商家接驳，多渠道曝光，精准消费者触达</span>
              <span className={styles.bad}>社区周边业态同质化严重，消费者触点单一，商家营销难</span>
            </div>
          </div>
          <div className={styles.item}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/22/b62db3125a4042a383903266c221c876"
              }
              alt=""
              style={{ width: 285, height: 300 }}
            />
            <div className={styles.itemTitle}>政府机构</div>
            <div className={styles.itemText}>
              <span className={styles.good}>政府办事直通车，行政服务更高效</span>
              <span className={styles.bad}>政府宣传难深入，行政服务效率低、成本高</span>
            </div>
          </div>
          <div className={styles.item}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/f84be361de144b87a9a442a0c5becd96"
              }
              alt=""
              style={{ width: 285, height: 300 }}
            />
            <div className={styles.itemTitle}>社区业主</div>
            <div className={styles.itemText}>
              <span className={styles.good}>安全便捷的社区体验、丰富的增值服务、和谐友善的社区氛围，开启美好生活</span>
              <span className={styles.bad}>社区设施老旧，生活不便；物业服务不到位、效率低下</span>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Win;
