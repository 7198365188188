import React, { useEffect,useState} from "react"
import Layout from "../components/layout/index"
import NavVideo from "../components/home/nav-video/index"
import Banner from '../components/home/banner/index'
import Win from '../components/home/win/index'
import Clound from '../components/home/clound/index'
import Goods from '../components/home/goods/index'
import SmartSence from '../components/home/smart-sence/index'
import Customers from "../components/home/customers/index"
import Firends from "../components/home/friends/index"

// import Image from "../components/image"
import SEO from "../components/seo"

// const Firends = ()=>{
//   return <></>
// }

const IndexPage = () => {
  const bannerJson ={
    title:'数据驱动，技术赋能',
    subTitle:'打造联接每一户的美好社区体验'
  }
  const [ready, setReady] = useState(false)
  useEffect(() => { // 做一个500 毫秒的延迟，让页面的跳转不闪屏
    setTimeout(()=>{
      setReady(true)
    },500)
  }, [])
  if(!ready){
    return null
  }
  return(
  <Layout>
    <SEO title="联每户" />
    <NavVideo />
    <Banner json={bannerJson} containerStyle={{marginTop: -100}}/>
    {/* <Banner containerStyle={{marginTop: -100}}/> */}
    <Win />
    {/* 端云一体 */}
    <Clound />
    {/* 核心优势 */}
    <Goods />
    {/* 智能场景 */}
    <SmartSence />
    {/* 服务对象 */}
    <Customers />
    {/* 合作伙伴 */}
    <Firends />
  </Layout>
  )
}

export default IndexPage
