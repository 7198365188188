import React from 'react';
import styles from './index.module.css'
const Friends = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>合作伙伴</div>
        <div className={styles.subTitle}>携手领先的伙伴，共创品质服务</div>
        <div className={styles.logos}>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/c474e317b5c4443485a167649fc8bf2e"
              }
              alt="中天物业"
            />
          </div>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/6ed1b2a18c3c4201ad7df96d1d11aafe"
              }
              alt=""
            />
          </div>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/580e1194f1c4431ab896d17f400f7fc7"
              }
              alt=""
            />
          </div>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/798b14e652084a938d0dee7c4b1e0f23"
              }
              alt=""
            />
          </div>
        </div>
        <div className={styles.logos}>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/8fa9f2705be844e098fba1c73c294596"
              }
              alt=""
            />
          </div>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/2ebe422ac96f4fae97ea446d70402e8f"
              }
              alt=""
            />
          </div>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/16c4a673d5b24b6a9db04b098d1018e0"
              }
              alt=""
            />
          </div>
          <div className={styles.logo}>
            <img
              src={
                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/deb2cf95caad47d086820809e5476926"
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Friends
