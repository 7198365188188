import React from 'react';
import styles from './index.module.css'

const Clound = () => {
  const bgImage =
    "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/43bcfc64cf7b4e9ea25db3a460c1d7d1"
  console.log(bgImage)
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>端云一体 高度集成</div>
        <div className={styles.subTitle}>
          领先的系统化方案能力，全面交付社区智能化所需
        </div>
      </div>
      <div
        className={styles.imageContent}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className={styles.content}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/30eb1a4e912349bc8b3b6b8b9d1edb24"
            }
            alt=""
            style={{
              height: 659,
              marginTop: 17,
              width: "auto",
              marginLeft: -22,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Clound
