import React from "react"
import styles from "./index.module.css"
const Customers = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>服务对象</div>
        <div className={styles.subTitle}>
          为社区空间运营、管理、服务者提供优质的方案服务
        </div>
        <div className={styles.group}>
          <div className={styles.item}>
            <div className={styles.itemImage}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/8bca2dc8880545e2b16184637dc8039b"
                }
                alt=""
              />
            </div>
            <div className={styles.itemText}>地产开发商</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemImage}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/2c5eb7e643ec43ceaf39766c1b70479c"
                }
                alt=""
              />
            </div>
            <div className={styles.itemText}>物业公司</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemImage}>
              <img
                src={
                  "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/3e2a289173bb4a9aa7c4f2a676d34d73"
                }
                alt=""
              />
            </div>
            <div className={styles.itemText}>政府机构</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
